import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import anime from 'animejs';

import { Link } from '../Link';

class Component extends React.Component {
  static displayName = 'Brand';

  static propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    energy: PropTypes.object.isRequired,
    audio: PropTypes.object.isRequired,
    sounds: PropTypes.object.isRequired,
    className: PropTypes.any,
    link: PropTypes.string,
    hover: PropTypes.bool,
    stableTime: PropTypes.bool,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onLinkStart: PropTypes.func,
    onLinkEnd: PropTypes.func
  };

  static defaultProps = {
    link: '/'
  };

  constructor () {
    super(...arguments);

    const { energy, stableTime } = this.props;

    if (!stableTime) {
      energy.updateDuration({ enter: 820 });
    }
  }

  componentWillUnmount () {
    const paths = this.svgElement.querySelectorAll('path');
    anime.remove(paths);
  }

  enter () {
    const { energy, sounds, stableTime, onEnter } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    anime.set(this.svgElement, { opacity: 1 });

    sounds.logo.play();

    anime({
      targets: paths,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      delay: (path, index) => stableTime ? 0 : index * energy.duration.stagger,
      duration: path => stableTime ? energy.duration.enter : path.getTotalLength(),
      complete: () => {
        onEnter && onEnter();
      }
    });
  }

  exit () {
    const { energy, sounds, onExit } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    sounds.fade.play();

    anime({
      targets: this.svgElement,
      easing: 'easeInCubic',
      duration: energy.duration.exit,
      opacity: 0
    });
    anime({
      targets: paths,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      direction: 'reverse',
      duration: energy.duration.exit,
      complete: () => {
        anime.set(this.svgElement, { opacity: 0 });
        onExit && onExit();
      }
    });
  }

  render () {
    const {
      theme,
      classes,
      energy,
      audio,
      sounds,
      className,
      link,
      hover,
      stableTime,
      onEnter,
      onExit,
      onLinkStart,
      onLinkEnd,
      ...etc
    } = this.props;

    return (
      <h1 className={cx(classes.root, hover && classes.hover, className)} {...etc}>
        <Link
          className={classes.link}
          href={link}
          title='Spiros G Logo'
          onLinkStart={onLinkStart}
          onLinkEnd={onLinkEnd}
        >
          <span className={classes.title}>Spiros G.</span>
          <svg
            ref={ref => (this.svgElement = ref)}
            className={classes.svg}
            viewBox='0 0 530 72'
            xmlns='http://www.w3.org/2000/svg'
            onMouseEnter={() => sounds.hover.play()}
          >
            <path className={classes.path} d='M 73.389 31.055 L 73.389 46.582 L 62.305 46.582 L 62.305 42.09 L 30.469 42.09 L 0 12.158 L 0 0 L 73.389 0 L 73.389 11.084 L 14.697 11.084 L 35.01 31.055 L 73.389 31.055 Z M 73.389 51.611 L 73.389 68.799 L 0 68.799 L 0 57.764 L 62.305 57.764 L 62.305 51.611 L 73.389 51.611 Z' />
            <path className={classes.path} d='M 89.307 42.09 L 89.307 68.799 L 78.271 68.799 L 78.271 0 L 157.178 0 L 157.178 12.158 L 131.934 37.012 L 123.975 29.248 L 142.48 11.084 L 89.307 11.084 L 89.307 31.055 L 118.604 31.055 L 129.883 42.09 L 89.307 42.09 Z' />
            <path className={classes.path} d='M 173.096 0 L 173.096 46.582 L 162.061 46.582 L 162.061 0 L 173.096 0 Z M 173.096 51.611 L 173.096 68.799 L 162.061 68.799 L 162.061 51.611 L 173.096 51.611 Z' />
            <path className={classes.path} d='M 218.311 31.055 L 256.885 68.799 L 241.064 68.799 L 213.77 42.09 L 189.014 42.09 L 189.014 46.582 L 177.979 46.582 L 177.979 0 L 256.885 0 L 256.885 12.158 L 231.592 37.012 L 223.682 29.248 L 242.139 11.084 L 189.014 11.084 L 189.014 31.055 L 218.311 31.055 Z M 189.014 51.611 L 189.014 68.799 L 177.979 68.799 L 177.979 51.611 L 189.014 51.611 Z' />
            <path className={classes.path} d='M 272.803 41.895 L 300.342 68.848 L 284.521 68.848 L 261.768 46.582 L 261.768 0 L 272.803 0 L 272.803 0.098 L 286.182 0.098 L 297.51 11.133 L 272.803 11.133 L 272.803 41.895 Z M 331.934 22.314 L 331.934 68.848 L 320.85 68.848 L 320.85 68.799 L 307.52 68.799 L 296.191 57.764 L 320.85 57.764 L 320.85 26.953 L 293.359 0 L 309.131 0 L 331.934 22.314 Z' />
            <path className={classes.path} d='M 410.205 31.055 L 410.205 46.582 L 399.121 46.582 L 399.121 42.09 L 367.285 42.09 L 336.816 12.158 L 336.816 0 L 410.205 0 L 410.205 11.084 L 351.514 11.084 L 371.826 31.055 L 410.205 31.055 Z M 410.205 51.611 L 410.205 68.799 L 336.816 68.799 L 336.816 57.764 L 399.121 57.764 L 399.121 51.611 L 410.205 51.611 Z' />
            <path className={classes.path} d='M 450.928 41.895 L 478.467 68.848 L 462.646 68.848 L 439.893 46.582 L 439.893 22.314 L 462.646 0 L 513.574 0 L 513.574 11.084 L 467.188 11.084 L 450.928 26.953 L 450.928 41.895 Z M 475 31.055 L 513.574 68.799 L 485.596 68.799 L 474.316 57.764 L 486.475 57.764 L 470.459 42.09 L 458.447 42.09 L 458.447 31.055 L 475 31.055 Z' />
            <path className={classes.path} d='M 529.932 59.961 L 529.932 70.215 L 518.457 70.215 L 518.457 59.961 L 529.932 59.961 Z' />
          </svg>
        </Link>
      </h1>
    );
  }
}

export { Component };
